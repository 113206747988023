@import "./colors.less";

.header-app {
  background-color: @black;
}
.header-homepage {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo-wrapper {
    // img {
    //   width: 130px;
    // }
    color: inherit;
  }

  .homepage-menu {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.banner-image {
  object-fit: scale-down;
  width: 500px;
}

.header-action-container {
  display: grid;
  gap: 15px;
  align-items: center;
  grid-template-columns: 1fr 2px 1fr 120px;
  padding: 16px;
  box-shadow: 0px 12px 24px -8px rgba(0, 0, 0, 0.15),
    0px 1px 1px rgba(0, 0, 0, 0.06);
  border-radius: 100px;
  background-color: @white;
}

.separator-vertical {
  width: 1px;
  height: 100%;
  background-color: @ash400;
}

.btn-action {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @primary;
  color: @white;
  border-radius: 100px;
  border: none;
  font-size: 16px;
  font-weight: 700;
}

.action-btn-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    border: 1px solid @ash400;
  }

  .content {
    margin-left: 10px;
    flex: 1;

    .label {
      font-size: 14px;
      line-height: 150%;
      color: @charcoal400;
    }
    .value-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .value {
      font-size: 16px;
      line-height: 150%;
      font-weight: 700;
      color: @black;
    }
  }
}

.homepage-section {
  margin: 150px 0;
}

.slider-actions {
  display: flex;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }

  button {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @primary;
    border-radius: 100px;
    border: none;
    transition: 500ms all ease;
  }

  button:hover {
    opacity: 0.7;
  }
}

.top-pick-card {
  position: relative;
  margin-right: 25px;

  cursor: pointer;

  span {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px 16px;
    background-color: @white;
    color: @black;
    font-weight: 700;
    border-radius: 100px;
  }

  img {
    border-radius: 20px;
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin-bottom: 15px;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: @black;
  }

  p {
    color: @charcoal400;
  }
}

.how-container {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  gap: 100px;
}

.how-index {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #14b5b0;
  border-radius: 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: @white;
  margin-bottom: 40px;
}

.how-title {
  font-weight: 700;
  font-size: 38px;
  line-height: 120%;
  color: @charcoal800;
  margin-bottom: 40px;
}

.how-description {
  font-size: 16px;
  line-height: 200%;
  color: @charcoal800;
}

.how-image-wrapper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  img {
    width: 400px;
    height: 400px;
    object-fit: contain;
  }
}

.location-slider-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
}

.btn-slick {
  width: 42px;
  height: 42px;
  background-color: @primary;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.location-list-container {
  width: 986px;
}

.location-card {
  padding: 20px;
  height: 153px;
  border-radius: 15px;
  margin-right: 20px;
  border: 1px solid @ash400;
  cursor: pointer;

  h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: @charcoal800;
  }

  p {
    margin-top: 10px;
    line-height: 150%;
    color: @charcoal400;
  }
}

.location-card-active {
  border: 1px solid @primary;
}

.faqs-link-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.faqs-link {
  padding: 15px 30px;
  background-color: @primary;
  border-radius: 100px;
  color: @white;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.header-web {
  position: relative;
}

.bottom-banner-wrapper {
  border-radius: 36px;
  background-color: @primary;
  max-width: 1100px;
  height: 300px;
  margin: 0 auto;
  background-image: url("./images/bg-banner-bottom.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 80px;
  background-position-x: 200px;
  background-position-y: top;

  h1 {
    font-size: 42px;
    line-height: 120%;
    font-weight: 800;
    color: @white;
    max-width: 60%;
  }

  .bottom-banner-link {
    display: flex;
    align-items: center;
    background-color: @white;
    border-radius: 100px;
    padding: 12px 24px;
    color: @primary;

    span {
      font-weight: 700;
      font-size: 18px;
    }

    img {
      margin-right: 10px;
    }
  }
}

.drawer-nav {
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawer-menu {
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  a {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
.user-info-wrapper {
  display: flex;
  align-items: center;

  .user-info {
    margin-left: 10px;
  }

  span {
    color: @ash800;
  }
}

.user-menu {
  display: flex;
  flex-direction: column;
  a {
    margin-top: 15px;
    font-size: 16px;
  }
}

.user-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.user-info-signin-wrapper {
  text-align: center;
}

.menu-footer-container {
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.btn {
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 42px;
  padding: 8px 16px;
  border: none;
  background: @primary;
  color: @white;

  // span {
  //   margin-left: 10px;
  // }
}

.btn-outlined {
  border: 1px solid @primary;
  background-color: @white;
  color: @primary;
}
