@import "./colors.less";

.auth-app {
  .content-form {
    flex: 1;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
  }
  .form-auth {
    .info {
      color: @gray500;
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    .input-container {
      margin-top: 10px;
      margin-bottom: 0;
      label {
        font-size: 14px;
        line-height: 16px;
      }
      &:nth-child(1) {
        margin-top: 0;
      }
      .input-app {
        // &:hover,
        // &:focus {
        //   border-color: #d9d9d9;
        //   box-shadow: none;
        // }
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        &.ant-input-affix-wrapper {
          .ant-input-prefix {
            margin: 0;
          }
          .hide {
            display: none;
          }
          .ant-input {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .button-container {
      margin: 25px 0 0;
      .button-app {
        width: 100%;
        background-color: @primary;
        color: @white;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
        border-radius: 6px;
        &:hover {
          background-color: lighten(@primary, 5%);
        }
        &.disabled {
          color: #595959;
          background-color: #cbcccc;
          &:hover {
            cursor: not-allowed;
            background-color: #cbcccc;
          }
        }
      }
    }
    .other-link {
      color: @black;
      &:hover {
        color: lighten(@black, 20%);
      }
    }
  }
}

@primary-color: #000;@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #000;@menu-highlight-color: #FFFFFF;