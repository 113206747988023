@import "./colors.less";

.voucher-generator {
  .banner {
    padding: 50px 0 40px;
    background: @black;
    display: flex;
    flex-flow: column;
    align-items: center;
    h1,
    p {
      color: @white;
      text-align: center;
      margin: 0;
    }
    h1 {
      font-weight: bold;
      font-size: 20px;
      line-height: 24.2px;
    }
    p {
      margin-top: 10px;
      font-size: 14px;
      line-height: 16.94px;
    }
  }
  .tab-selection {
    display: flex;
    flex-flow: row wrap;
    .tab-item {
      cursor: pointer;
      flex: 1;
      padding: 15px;
      &.active {
        border-bottom: 3px solid @black;
        h5 {
          color: @black;
        }
      }
      h5 {
        margin: 0;
        text-align: center;
        font-size: 14px;
        line-height: 16.94px;
        color: @blackInActive;
      }
    }
  }
  .form-voucher {
    padding: 20px;
    .info {
      color: @gray500;
      margin-top: 8px;
      font-size: 14px;
      line-height: 20px;
    }
    .input-container {
      margin-top: 15px;
      margin-bottom: 0;
      label {
        font-size: 14px;
        line-height: 16px;
      }
      &:nth-child(1) {
        margin-top: 0;
      }
      .input-app {
        // &:hover,
        // &:focus {
        //   border-color: #d9d9d9;
        //   box-shadow: none;
        // }
        font-size: 16px;
        line-height: 24px;
        padding: 8px 16px;
        &.ant-input-affix-wrapper {
          .ant-input-prefix {
            margin: 0;
          }
          .hide {
            display: none;
          }
          .ant-input {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .button-container {
      margin: 25px 0 0;
      .button-app {
        width: 100%;
        background-color: @black;
        color: @white;
        font-size: 14px;
        line-height: 28px;
        font-weight: 600;
        border-radius: 6px;
        &:hover {
          background-color: lighten(@black, 20%);
        }
        &.disabled {
          background-color: #cbcccc;
          &:hover {
            cursor: not-allowed;
            background-color: #cbcccc;
          }
        }
      }
    }
  }
}

.modal-with-status {
  .modal-top {
    .icon-status-container {
      margin: 5px auto 0;
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      margin: 25px 0;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 24.2px;
    }
  }
  .border-bot {
    border-bottom: 2px dashed #cbcccc;
  }

  .voucher-container {
    width: 220px;
    margin: 10px auto 0;
    background-color: #eaeaea;
    padding: 10px 47px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .voucher-code {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }
  .text-title-voucher {
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    text-align: center;
  }
  .text-valid {
    margin: 10px 0 0;
    text-align: center;
  }
  .terms {
    margin: 15px 0 0;
    padding-left: 20px;
    li {
      list-style: disc;
    }
  }
  .button-container {
    .button-app {
      width: 100%;
      background-color: @black;
      color: @white;
      font-size: 14px;
      line-height: 28px;
      font-weight: 600;
      border-radius: 6px;
      &:hover {
        background-color: lighten(@black, 20%);
      }
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width {
  width: 100%;
}
@primary-color: #000;@border-radius-base: 5px;@text-selection-bg: rgba(0,0,0,0.3);@menu-item-active-bg: #000;@menu-highlight-color: #FFFFFF;