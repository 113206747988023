@import "./colors.less";

.layout-content {
  margin: 0 auto;
}

.layout {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
.content-app {
  flex: 1;
}

.logo-app {
  color: #ffffff;
}

header {
  width: 100%;
}

.layout-header {
  padding-top: 10;
  padding-bottom: 60;
  box-shadow: "0px 1px #f2f2f2";
  z-index: 10;
}

.header-homepage {
  padding: 16px 20px;
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .menu {
    flex: 1;

    .ant-menu {
      background-color: transparent;
    }
  }
}

.footer-container {
  background-color: @white;
  padding: 20px;
  border-top: 1px solid #d8d8d8;
}

.footer-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  p {
    margin: 0;
    opacity: 0.5;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }
}
